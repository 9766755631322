<template>
  <div class="discountInfo">
    <div class="title">
      折扣促销详情
      <span class="tag" v-if="status == '接下来的活动'">接下来的活动</span>
      <span class="tag1" v-if="status == '进行中的活动'">进行中的活动</span>
      <span class="tag2" v-if="status == '已过期'">已过期</span>
    </div>
    <div class="cell1">
      <div class="titles">基本信息</div>
      <div class="info">
        <div>
          折扣促销类型：
          <span style="color: #000">{{ infoData.discount_type_txt }}</span>
        </div>
        <div>
          折扣促销名称：
          <span style="color: #000">{{ infoData.discount_name }}</span>
        </div>
        <div>
          折扣促销时期：
          <span style="color: #000">{{ infoData.time }}</span>
        </div>
      </div>
    </div>
    <div class="cell2">
      <div class="titles">折扣促销产品</div>
      <div style="margin-top: 20px">
        <el-input
          size="small"
          style="width: 530px"
          v-model="input3"
          class="input-with-select"
        >
          <el-select
            style="width: 180px"
            v-model="select"
            slot="prepend"
            placeholder="请选择"
          >
            <el-option label="商品名称" value="1"></el-option>
            <!-- <el-option label="商品ID" value="2"></el-option> -->
          </el-select>
          <el-button
            @click="getList"
            slot="append"
            icon="el-icon-search"
          ></el-button>
        </el-input>
      </div>
      <div style="margin: 20px 0; font-size: 12px">
        共{{ listData.list && listData.list.length }}件商品
      </div>
      <div class="list">
        <div class="list-head">
          <div style="width: 10%">商品名称</div>
          <div style="width: 10%">原价</div>
          <div style="width: 10%">售价</div>
          <div style="width: 10%">折扣</div>
          <div style="width: 10%">库存</div>
          <div style="width: 10%">促销库存</div>
          <div style="width: 10%">购买限制</div>
        </div>
        <div class="list-content" v-for="item in listData.list" :key="item.id">
          <div style="border: 1px solid #eee; margin-top: 20px">
            <div class="one">
              <div style="width: 30%; display: flex; align-items: center">
                <el-image
                  style="min-width: 40px; height: 40px; margin-right: 10px"
                  :src="item.goods_image"
                  fit="fit"
                ></el-image>
                <div class="single-hidden" style="width: 300px">
                  {{ item.goods_name }}
                </div>
              </div>
              <div style="width: 10%">
                {{ item.buyer_limit == 0 ? "无限制" : item.buyer_limit }}
              </div>
            </div>
            <div
              class="two"
              v-for="val in item.discountGoodsConfig"
              :key="val.id"
            >
              <div style="width: 10%">
                {{ val.difference ? val.difference : "-" }}
              </div>
              <div style="width: 10%">RM{{ val.goods_price }}</div>
              <div style="width: 10%">RM{{ val.discount_price }}</div>
              <div style="width: 10%">
                <div class="btn">{{ parseInt(val.discount_ratio) }}%折扣</div>
              </div>
              <div style="width: 10%">{{ val.stock }}</div>
              <div style="width: 10%">
                {{ val.stock_limit == 0 ? "无限制" : val.stock_limit }}
              </div>
              <div style="width: 10%"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex; justify-content: right; margin-top: 30px">
      <el-button @click="toBack" size="small" style="min-width: 120px" plain
        >返回列表页</el-button
      >
    </div>
  </div>
</template>
<script>
import { getDiscountInfo, discountDetails } from "@/api/marketing.js";
export default {
  data() {
    return {
      select: "1",
      input3: "",
      infoData: "",
      listData: "",
      status: "",
    };
  },
  created() {
    this.getInfo();
    this.getList();
  },
  methods: {
    getInfo() {
      getDiscountInfo({
        discount_id: sessionStorage.DISCOUNTID,
      }).then((res) => {
        if (res.code == 1) {
          this.status = res.data.status_txt;
          this.infoData = res.data;
          this.infoData.time =
            this.formatTime(res.data.start_time) +
            "-" +
            this.formatTime(res.data.end_time);
        }
      });
    },
    getList() {
      discountDetails({
        id: sessionStorage.DISCOUNTID,
        goods_name: this.select == 1 ? this.input3 : "",
        goods_id: this.select == 2 ? this.input3 : "",
        page: 1,
        page_size: 100,
      }).then((res) => {
        if (res.code == 1) {
          this.listData = res.data;
        }
      });
    },
    toBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.discountInfo {
  width: 1400px;
  margin: 0 auto;
  padding-top: 20px;
  .title {
    font-size: 20px;
    .tag1 {
      background: #eaf9ef;
      color: #55cc77;
      display: initial;
      padding: 2px 4px;
      border-radius: 2px;
      font-size: 12px;
    }
    .tag {
      background: #fff1f0;
      color: #ee4d2d;
      display: initial;
      padding: 2px 4px;
      font-size: 12px;
      border-radius: 2px;
    }
    .tag2 {
      background: #eeeeee;
      color: #666;
      font-size: 12px;
      display: initial;
      padding: 2px 4px;
      border-radius: 2px;
    }
  }
  .cell1 {
    padding: 20px;
    background: #fff;
    margin-top: 20px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
    .titles {
      font-size: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .info {
      display: flex;
      justify-content: space-between;
      padding-top: 30px;
      font-size: 14px;
      color: #929495;
    }
  }
  .cell2 {
    padding: 20px;
    background: #fff;
    margin-top: 20px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
    .titles {
      font-size: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .list {
      .list-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        background: #f6f6f6;
        border-radius: 4px;
        padding: 10px 20px;
        border: 1px solid #eee;
      }
      .list-content {
        font-size: 14px;
        .one {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 20px;

          background: #fafafa;
        }
        .two {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px 20px;
          .btn {
            border: 1px solid #f07b68;
            display: initial;
            color: #f07b68;
            font-size: 12px;
            padding: 1px 4px;
            border-radius: 4px;
          }
        }
      }
    }
  }
}
</style>